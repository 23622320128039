import styles from "../pages/index.module.scss"

const tagStyles = {
  JavaScript: styles.yellow,
  "Computer Science": styles.orange,
  Mathematics: styles.crimson,
  Genetics: styles.green,
  Redux: styles.blue,
  Neuroscience: styles.purple,
  Japan: styles.pink,
  History: styles.seagreen,
  "Web Development": styles.salmon,
  Docker: styles.cadmiumorange,
  Redis: styles.indianred,
  React: styles.lightpurple,
  GraphQL: styles.grassgreen,
  "Express.js": styles.palepink,
  Ornithology: styles.aeroblue,
  TypeScript: styles.candypink,
  Typography: styles.indigo,
  "Node.js": styles.ivygreen,
  "DevOps": styles.firered,
  default: styles.mediumpurple,
}

export { tagStyles }
